import React from 'react';
import PropTypes from 'prop-types';

function FilledStar(props) {
  const { color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill={color}
          fillRule="nonzero"
          d="M12 18.6611884L4.55769231 23 6.54807692 14.8508217 0 9.37168142 8.625 8.64854614 12 1 15.375 8.64854614 24 9.37168142 17.4807692 14.8508217 19.4423077 23z"
        />
      </g>
    </svg>
  );
}

FilledStar.propTypes = {
  color: PropTypes.string.isRequired,
};

export default FilledStar;
