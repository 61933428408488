import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import Taxon from '../../../header/components/mobile_menu/taxon/Taxon';
import targetImage from '../../../../images/icons/target.svg';
import { taxonsSelector } from '../../../cart/selectors/taxons';

const DESKTOP_WIDTH_BREAKPOINT = 800;

function Taxons(props) {
  const { taxons } = props;

  if (!taxons) { return null; }

  const taxonsWithChildren = taxons.filter((taxon) => (taxon.children.length > 0));
  const taxonsWithoutChildren = taxons.filter((taxon) => (taxon.children.length === 0));

  return (
    <>
      <MediaQuery query={`(max-width: ${DESKTOP_WIDTH_BREAKPOINT - 1}px)`}>
        <div className="without-children mobile">
          { taxons.map(
            (taxon) => (
              <Taxon
                key={taxon.id}
                taxon={{
                  url: taxon.url,
                  name: taxon.name,
                  children: [],
                }}
              />
            ),
          )}
        </div>
      </MediaQuery>
      <MediaQuery query={`(min-width: ${DESKTOP_WIDTH_BREAKPOINT}px)`}>
        <div className="without-children">
          { taxonsWithoutChildren.map(
            (taxon) => (
              <Taxon
                key={taxon.id}
                taxon={{
                  url: taxon.url,
                  name: taxon.name,
                  children: [],
                }}
              />
            ),
          )}
        </div>
        <div className="with-children">
          { taxonsWithChildren.map(
            (taxon) => (
              <Taxon
                key={taxon.id}
                taxon={{
                  url: taxon.url,
                  name: taxon.name,
                  children: taxon.children,
                }}
              />
            ),
          )}
        </div>
      </MediaQuery>
      <div className="target">
        <img src={targetImage} alt="target" />
      </div>
    </>
  );
}

Taxons.propTypes = {
  taxons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  taxons: taxonsSelector(state),
});

export default connect(mapStateToProps)(Taxons);
