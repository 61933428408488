import React from 'react';
import PropTypes from 'prop-types';
import styles from './Rating.module.sass';
import FilledStar from './FilledStar';
import UnfilledStar from './UnfilledStart';

const MAX_RATING = 5;

function Rating(props) {
  const { rating, color = '#9DACE4' } = props;

  const stars = [];

  for (let i = 0; i < MAX_RATING; i += 1) {
    const star = [];

    const difference = rating - i;
    let className = `${styles.normal} `;

    if (difference < 1) {
      className += styles[`__${rating.toString().split('.')[1]}`];
    }

    if (difference > 0) {
      star.push(
        <div key={`filled ${i}`} className={className}>
          <FilledStar color={color} />
        </div>,
      );
    }

    star.push(
      <div key={`unfilled ${i}`} className={styles.empty}>
        <UnfilledStar color={color} />
      </div>,
    );

    stars.push(star);
  }

  const { className } = props;

  return (
    <div className={`${styles.rating} ${className}`}>
      { stars.map((star, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.star}>
          {star}
        </div>
      ))}
    </div>
  );
}

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Rating;
