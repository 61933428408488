import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import Link from '../link/Link';
import './Taxon.sass';
import plusSign from '../../../../../images/icons/plus-sign-blue.svg';
import minusSign from '../../../../../images/icons/minus-sign-blue.svg';
import ClickableDiv from '../../../../ui_kit/clickable_div/ClickableDiv';

function Taxon(props) {
  const {
    taxon, additionalChild, expandable = false, className,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`taxon ${className || ''}`}>
      <div className="row">
        <Link href={taxon.url} title={taxon.name} color={taxon.taxon_color} main />
        { expandable && taxon.children.length > 0 && (
          <ClickableDiv onClick={toggle}>
            <img src={expanded ? minusSign : plusSign} alt="plus sign" width="8" />
          </ClickableDiv>
        )}
      </div>
      <AnimateHeight
        duration={400}
        height={(!expandable || expanded) ? 'auto' : 0}
        className="content"
      >
        { taxon.children.map((childTaxon) => (
          <Link
            key={childTaxon.id}
            href={childTaxon.url}
            title={childTaxon.name}
            color={childTaxon.taxon_color}
          />
        ))}
        {additionalChild}
      </AnimateHeight>
    </div>
  );
}

Taxon.propTypes = {
  taxon: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    taxon_color: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        url: PropTypes.string,
        taxon_color: PropTypes.string,
      }),
    ),
  }).isRequired,
  additionalChild: PropTypes.node,
  expandable: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

export default Taxon;
