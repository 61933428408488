import React from 'react';
import PropTypes from 'prop-types';
import Stars from '../../rating/Rating';
import './Rating.sass';

function Rating(props) {
  const { rating, url } = props;

  if (rating === 0) { return null; }

  return (
    <a href={`${url}#react-reviews`}>
      <div className="rating">
        <div>
          <span className="rating-value">{rating}</span>
        </div>
        <Stars rating={rating} className="stars" />
      </div>
    </a>
  );
}

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

export default Rating;
