import React from 'react';
import PropTypes from 'prop-types';

function UnfilledStar(props) {
  const { color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill={color}
          fillRule="nonzero"
          d="M12 18.661L4.558 23l1.99-8.15L0 9.373l8.625-.723L12 1l3.375 7.649L24 9.372l-6.52 5.479L19.443 23 12 18.661zm-5.912 2.29L12 17.504l5.922 3.452-1.558-6.473 5.137-4.317-6.801-.57L12 3.476 9.3 9.595l-6.794.57 5.161 4.318-1.58 6.468z"
        />
      </g>
    </svg>
  );
}

UnfilledStar.propTypes = {
  color: PropTypes.string.isRequired,
};

export default UnfilledStar;
