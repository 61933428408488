import React from 'react';
import PropTypes from 'prop-types';
import './Price.sass';

function Price(props) {
  const { price, discount, className } = props;
  return (
    <div className={`price ${className}`}>
      { discount > 0 && (
        <div className="full-price">
          {`$${Number(price)}`}
        </div>
      )}
      <span itemProp="offers" itemScope itemType="https://schema.org/Offer">
        <div className="total">
          <span itemProp="priceCurrency" content="USD">$</span>
          <span itemProp="price" content={price - discount}>{price - discount}</span>
          <link itemProp="availability" href="http://schema.org/InStock" />
        </div>
      </span>
    </div>
  );
}

Price.propTypes = {
  discount: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default Price;
