import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductPreview from '../products/components/product_preview/ProductPreview';
import { productsActions } from '../products/actions';
import styles from './BestSellers.module.sass';
import { bestSellersActions } from '../homepage/actions';
import { bestSellersSelector } from '../homepage/selectors';

const NUMBER_BESTSELLERS_TO_SHOW = 3;

class BestSellers extends Component {
  componentDidMount() {
    const { loadBestSellersRequest } = this.props;

    loadBestSellersRequest();
  }

  render() {
    const {
      addToBag, bestSellers, buttonColor = 'black', className,
    } = this.props;

    if (bestSellers.length === 0) { return null; }

    return (
      <div className={`${styles['best-sellers']} best-sellers ${className || ''}`}>
        <div className={styles.content}>
          <div className={styles.title}>
            Best Sellers
          </div>
          <div className={styles.products}>
            { bestSellers.slice(0, NUMBER_BESTSELLERS_TO_SHOW).map((product) => (
              <ProductPreview
                key={product.id}
                product={product}
                addToBag={addToBag}
                className={styles['product-preview']}
                buttonColor={buttonColor}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

BestSellers.propTypes = {
  loadBestSellersRequest: PropTypes.func.isRequired,
  addToBag: PropTypes.func.isRequired,
  bestSellers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  buttonColor: PropTypes.oneOf([
    'black', 'white', 'blue', 'transparent-blue', 'yellow', 'green', 'transparent-green',
  ]),
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  bestSellers: bestSellersSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  addToBag: (id) => dispatch(productsActions.addToBagRequest(id)),
  loadBestSellersRequest: () => dispatch(bestSellersActions.loadBestSellersRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BestSellers);
