import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Link.sass';

function Link(props) {
  const {
    href, main, title, color = 'inherit', className,
  } = props;
  return (
    <div className={classNames('link', { main }, className)} style={{ color }}>
      <a href={href}>
        {title}
      </a>
    </div>
  );
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  main: PropTypes.bool,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Link;
