import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Button from '../ui_kit/button/Button';
import BestSellers from '../best_sellers/BestSellers';
import Taxons from './components/taxons/Taxons';

document.addEventListener('DOMContentLoaded', () => {
  createRoot(
    document.getElementById('back-to-shop-btn'),
  ).render(
    <Button
      className="shop-button"
      text="Back To Shop"
      href="/shop/"
      size="small"
      color="transparent-blue"
    />,
  );

  createRoot(
    document.getElementById('categories'),
  ).render(
    <Provider store={window.store}>
      <Taxons />
    </Provider>,
  );

  createRoot(
    document.getElementById('best-sellers'),
  ).render(
    <Provider store={window.store}>
      <BestSellers buttonColor="blue" />
    </Provider>,
  );

  const StickyCategories = React.lazy(() => import('../homepage/components/sticky_categories/StickyCategories'));
  createRoot(
    document.getElementById('react-sticky-categories'),
  ).render(
    <Suspense fallback="">
      <Provider store={window.store}>
        <StickyCategories />
      </Provider>
    </Suspense>,
  );
});
