import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Rating from './rating/Rating';
import './ProductPreview.sass';
import ProductButton from '../product_button/ProductButton';
import Price from './price/Price';
import normalToDryIcon from '../../../../images/product/skin_types/normal_to_dry.svg';
import sensitiveIcon from '../../../../images/product/skin_types/sensitive.svg';
import oilyIcon from '../../../../images/product/skin_types/oily.svg';
import dryIcon from '../../../../images/product/skin_types/dry.svg';
import normalToOilyIcon from '../../../../images/product/skin_types/normal_to_oily.svg';
import normalIcon from '../../../../images/product/skin_types/normal.svg';
import comboIcon from '../../../../images/product/skin_types/combo.svg';

const ICON_BY_SKIN_TYPE = {
  'normal/dry': normalToDryIcon,
  sensitive: sensitiveIcon,
  oily: oilyIcon,
  normal: normalIcon,
  dry: dryIcon,
  'normal/oily': normalToOilyIcon,
  combo: comboIcon,
};

class ProductPreview extends Component {
  imageRef = React.createRef();

  previewTextRef = React.createRef();

  componentDidMount() {
    const { showPreviewText } = this.props;

    window.lazyLoad.update();
    if (showPreviewText) {
      this.onResize();
      window.addEventListener('resize', this.onResize);
      AOS.init();
    }
  }

  onResize = () => {
    if (!this.previewTextRef || !this.previewTextRef.style) { return; }

    this.previewTextRef.style.height = `${this.imageRef.clientHeight}px`;
  };

  addToBagClickHandler = () => {
    const { addToBag, product: { id, variantId } } = this.props;
    addToBag({ id, variantId });
  };

  render() {
    const {
      product, className, buttonColor = 'blue', addToBag, showSkinType, showPreviewText,
      showDiscountPercentage,
    } = this.props;
    const {
      id, name, image, imageWebp, imagePlaceholder, imagePlaceholderWebp, rating, subtitle,
      price, discount, url,
      reviewsCount, waitlistSubscribable, subscribed, available, variant,
      redirectionEnabled, redirectText, limitation, showAddToBag,
      loading, shortDescription, previewText, previewTextColor, redirectLink, sku,
      stickerText, skinType,
    } = product;

    const priceAmount = parseFloat(price);
    const discountAmount = parseFloat(discount);

    const showDiscountPercentageBlock = showDiscountPercentage
      && discountAmount > 0 && priceAmount > 0;

    return (
      <div className={`product-preview ${className}`}>
        <div className="content">
          <a href={url} className="link-container">
            <div className="image" ref={(ref) => { this.imageRef = ref; }}>
              <picture>
                <source suppressHydrationWarning data-srcset={imageWebp} srcSet={imagePlaceholderWebp} type="image/webp" />
                <img suppressHydrationWarning className="lazyload blur" src={imagePlaceholder} data-src={image} alt={name} />
              </picture>
              { stickerText && (
                <div className="sticker">
                  {stickerText}
                </div>
              )}
              <div className="hover-block">
                <div className="description">
                  {shortDescription}
                </div>
              </div>
              { showSkinType && skinType && (
                <div className="skin-type">
                  <div className="name">
                    {skinType}
                  </div>
                  <div className="icon">
                    <img src={ICON_BY_SKIN_TYPE[skinType]} alt={skinType} />
                  </div>
                </div>
              )}
            </div>
          </a>
          <div className="desktop-row">
            <div className="column">
              <a href={url}>
                <div className="name">{name}</div>
              </a>
              <div className="price-container">
                <Price className="mobile" price={priceAmount} discount={discountAmount} />
                {showDiscountPercentageBlock && (
                  <div className="discount-percentage">
                    {Math.floor((discountAmount / priceAmount) * 100)}
                    % OFF
                  </div>
                )}
              </div>
              { !showDiscountPercentageBlock && (
                <div className="description">
                  {subtitle}
                </div>
              )}
            </div>
            <Price className="desktop" price={priceAmount} discount={discountAmount} />
          </div>
          <Rating url={url} rating={rating} reviewsCount={reviewsCount} />
          { addToBag && (
            <ProductButton
              waitlistSubscribable={waitlistSubscribable}
              subscribed={subscribed}
              available={available}
              showAddToBag={showAddToBag}
              redirectionEnabled={redirectionEnabled}
              redirectText={redirectText}
              redirectLink={redirectLink}
              limitation={limitation}
              loading={loading}
              productUrl={url}
              addToBagClickHandler={this.addToBagClickHandler}
              price={priceAmount}
              productId={id}
              sku={sku}
              name={name}
              variant={variant}
              buttonColor={buttonColor}
              image={image}
            />
          )}
        </div>
        { showPreviewText && (
          <div
            className="preview-text"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-offset="200"
            style={{ color: previewTextColor }}
            suppressHydrationWarning
            ref={(ref) => { this.previewTextRef = ref; }}
          >
            {previewText}
          </div>
        )}
      </div>
    );
  }
}

ProductPreview.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    variantId: PropTypes.number,
    name: PropTypes.string,
    rating: PropTypes.number,
    image: PropTypes.string,
    imageWebp: PropTypes.string,
    imagePlaceholder: PropTypes.string,
    imagePlaceholderWebp: PropTypes.string,
    subtitle: PropTypes.string,
    price: PropTypes.string,
    discount: PropTypes.string,
    url: PropTypes.string,
    reviewsCount: PropTypes.number,
    waitlistSubscribable: PropTypes.bool,
    subscribed: PropTypes.bool,
    available: PropTypes.bool,
    redirectionEnabled: PropTypes.bool,
    redirectText: PropTypes.string,
    redirectLink: PropTypes.string,
    limitation: PropTypes.number,
    showAddToBag: PropTypes.bool,
    loading: PropTypes.bool,
    shortDescription: PropTypes.string,
    previewText: PropTypes.string,
    previewTextColor: PropTypes.string,
    sku: PropTypes.string,
    variant: PropTypes.string,
    stickerText: PropTypes.string,
    skinType: PropTypes.string,
  }).isRequired,
  addToBag: PropTypes.func,
  className: PropTypes.string,
  buttonColor: PropTypes.oneOf([
    'black', 'white', 'blue', 'transparent-blue', 'yellow', 'green', 'transparent-green',
  ]),
  showSkinType: PropTypes.bool,
  showPreviewText: PropTypes.bool,
  showDiscountPercentage: PropTypes.bool,
};

export default ProductPreview;
